import { type RouteLocationNormalizedLoaded, type RouteLocationNormalized, START_LOCATION } from 'vue-router'
import { AuthoritiesEnum } from '@/common/types/session'
import { APP_ROUTE_PATHS } from '@/app/types/router'
import { ADMIN_ROUTE_NAMES } from '@/admin/types/router'
import { getMenuByLink, userHaveAccessToMenu } from '@/admin/components/menu/menuTraverser'
import { useMenuStore } from '@/admin/stores/menuStore'
import { useEntityStore } from '@/common/stores/entityStore'
import { useSessionStore } from '@/common/stores/sessionStore'
import { isDisabledFeatureView } from '@/common/services/FeatureFlagService'

export const checkAccessRightsGuard = (to: RouteLocationNormalized) => {
  const sessionStore = useSessionStore()

  if (to.name === ADMIN_ROUTE_NAMES.ADMIN_ROOT) {
    if (sessionStore.getAuthoritySet.has(AuthoritiesEnum.ADMIN_USERS)) {
      return { path: '/assist/admin/users/' }
    }

    const menuStore = useMenuStore()
    const firstDisplayedMenuLink = menuStore.firstDisplayedMenuLink
    if (!firstDisplayedMenuLink) return { path: APP_ROUTE_PATHS.NOT_FOUND_PATH }
    return { path: firstDisplayedMenuLink }
  }

  if (!to?.meta?.technical) {
    const menuByLink = getMenuByLink(to.fullPath)
    if (!menuByLink) {
      return { path: APP_ROUTE_PATHS.NOT_FOUND_PATH }
    }

    if (!userHaveAccessToMenu(sessionStore.authorities, menuByLink)) return { path: APP_ROUTE_PATHS.NOT_FOUND_PATH }
  }
}

export const adminBeforeResolve = async (to: RouteLocationNormalized, from: RouteLocationNormalizedLoaded) => {
  if (to?.meta?.admin) {
    if (from === START_LOCATION || to?.meta?.invalidate || (from?.meta?.legacy && !to?.meta?.legacy)) {
      const entityStore = useEntityStore()
      await entityStore.fetchData()
    }
  }
}

export const knowledgeBaseBeforeEnter = (to: RouteLocationNormalized) => {
  if (isDisabledFeatureView(to.fullPath)) return { path: APP_ROUTE_PATHS.NOT_FOUND_PATH }
}
