import HttpService from '@/common/services/HttpService'
import {
  type AxialysConfigurationDTOV1,
  type CallDTOV1,
  type CallInteractionDTOV2,
  type CallRequestBody,
  type InboundCallConfigurationDTOV2,
  type OdigoConfigurationDTOV1,
  type OutboundCallConfigurationDTOV2,
  type PatchCallInteractionDTOV2,
  type ProviderGeneralConfigurationDTOV2,
  type VoicebotConfigurationDTOV1,
  type VoiceConfigurationDTOV1,
  VoiceProvider,
} from '@/admin/types/voice'
import { displayMessage } from '@/common/services/NotificationDisplayService'
import i18n from '@/plugins/i18n'
import { type CallParams, type CtiOdigoAgentAndStatus, type GenesysAgentDTOV2 } from '@/cti/types/cti'
import { useCtiStore } from '@/cti/stores/ctiStore'
import axios from 'axios'

const { t } = i18n.global

const configurationPath = `call/api/v1/configuration/`
const generalConfigurationsPath = `/call/api/v2/configurations`
const inboundCallConfigurationPath = `/call/api/v2/inbound-call-configurations`
const outboundCallConfigurationPath = `/call/api/v2/outbound-call-configurations`

const axialysOutboundCallConfigurationPath = `call/api/v1/axialys/outbound-call-configurations`
const axialysConfigurationsPath = `/call/api/v1/axialys/configuration`
const axialysInboundCallConfigurationPath = `call/api/v1/axialys/inbound-call-configurations`
const axialysConnectUrl = `call/api/v1/axialys/configuration/connect`

const odigoConnectUrl = `call/api/v1/odigo/configuration/connect`
const odigoOutboundCallConfigurationPath = `call/api/v1/odigo/outbound-call-configurations`
const odigoConfigurationsPath = `/call/api/v1/odigo/configuration`
const odigoInboundCallConfigurationPath = `call/api/v1/odigo/inbound-call-configurations`
const odigoAgentAndStatusPath = 'call/api/v1/odigo/agent-status'

const voicebotConfigurationsPath = `/call/api/v1/voicebot/configuration`
const voicebotInboundCallConfigurationPath = `call/api/v1/voicebot/inbound-call-configurations`

export const getVoiceProvider = async () => {
  try {
    return (await HttpService.getData<VoiceConfigurationDTOV1>(configurationPath))?.activeProvider ?? VoiceProvider.NONE
  } catch (error) {
    console.error(error)
    return VoiceProvider.NONE
  }
}

export const putVoiceConfiguration = async (activeProvider: VoiceProvider) => {
  try {
    const res = await HttpService.putData<VoiceConfigurationDTOV1>(configurationPath, { activeProvider })
    displayMessage(t('assist.admin.voice.provider.message.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.provider.message.update.error', { provider: activeProvider }), 'danger')
    return
  }
}

export const getAxialysOutboundCallConfigurations = async (): Promise<OutboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2[]>(axialysOutboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getAxialysOutboundCallConfigurationById = async (id: UUID) => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2>(`${axialysOutboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createAxialysOutboundCallConfiguration = async (axialysOutboundCallConfiguration: OutboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.postData(axialysOutboundCallConfigurationPath, axialysOutboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.outbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.create.error'), 'danger')
    return
  }
}

export const updateAxialysOutboundCallConfiguration = async (axialysOutboundCallConfiguration: OutboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.putData(
      `${axialysOutboundCallConfigurationPath}/${axialysOutboundCallConfiguration.id}`,
      axialysOutboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.axialys.outbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.update.error'), 'danger')
    return
  }
}
export const deleteAxialysOutboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${axialysOutboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.axialys.outbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.outbound.delete.error'), 'danger')
    return
  }
}
export const getAxialysInboundCallConfigurations = async (): Promise<InboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2[]>(axialysInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getAxialysInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2>(`${axialysInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createAxialysInboundCallConfiguration = async (axialysInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.postData(axialysInboundCallConfigurationPath, axialysInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.create.error'), 'danger')
    return
  }
}

export const updateAxialysInboundCallConfiguration = async (axialysInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    await HttpService.putData(`${axialysInboundCallConfigurationPath}/${axialysInboundCallConfiguration.id}`, axialysInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.axialys.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.update.error'), 'danger')
  }
}
export const deleteAxialysInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${axialysInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.axialys.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.inbound.delete.error'), 'danger')
    return false
  }
}

export const updateAxialysConfiguration = async (axialysConfiguration: AxialysConfigurationDTOV1) => {
  try {
    await HttpService.putData(`${axialysConfigurationsPath}`, axialysConfiguration)
    displayMessage(t('assist.admin.voice.axialys.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.axialys.configuration.update.error'), 'danger')
  }
}

export const getAxialysConfiguration = async (): Promise<AxialysConfigurationDTOV1 | undefined> => {
  try {
    return await HttpService.getData<AxialysConfigurationDTOV1>(`${axialysConfigurationsPath}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const generateAxialysToken = async () => {
  try {
    return await HttpService.putData(`${axialysConnectUrl}`)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.axialys.token.error'), 'danger')
    throw error
  }
}

export const getAllProvidersConfigurations = async (): Promise<ProviderGeneralConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<ProviderGeneralConfigurationDTOV2[]>(`${generalConfigurationsPath}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const createProviderConfiguration = async (configuration: ProviderGeneralConfigurationDTOV2): Promise<ProviderGeneralConfigurationDTOV2> => {
  try {
    const res = await HttpService.postData<ProviderGeneralConfigurationDTOV2>(`${generalConfigurationsPath}`, configuration)
    displayMessage(t('assist.admin.voice.provider.create.success'), 'success')
    return res
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.provider.create.error'), 'danger')
    throw error
  }
}

export const updateProviderConfiguration = async (configuration: ProviderGeneralConfigurationDTOV2): Promise<ProviderGeneralConfigurationDTOV2> => {
  try {
    const res = await HttpService.putData<ProviderGeneralConfigurationDTOV2>(`${generalConfigurationsPath}/${configuration.id}`, configuration)
    displayMessage(t('assist.admin.voice.provider.update.success'), 'success')
    return res
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.provider.update.error'), 'danger')
    throw error
  }
}

export const getAllInboundCallConfigurations = async (): Promise<InboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2[]>(`${inboundCallConfigurationPath}`)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getInboundCallConfigurationById = async (id: UUID | string): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2>(`${inboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}

export const createInboundCallConfiguration = async (
  inboundCallConfiguration: InboundCallConfigurationDTOV2,
): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    const res = await HttpService.postData<InboundCallConfigurationDTOV2>(inboundCallConfigurationPath, inboundCallConfiguration)
    displayMessage(t('assist.admin.voice.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.inbound.create.error'), 'danger')

    return
  }
}

export const updateInboundCallConfiguration = async (
  inboundCallConfiguration: InboundCallConfigurationDTOV2,
): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    const res = await HttpService.putData<InboundCallConfigurationDTOV2>(
      `${inboundCallConfigurationPath}/${inboundCallConfiguration.id}`,
      inboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.inbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.inbound.update.error'), 'danger')
    return
  }
}

export const deleteInboundCallConfiguration = async (id: UUID) => {
  try {
    const res = await HttpService.delete(`${inboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.inbound.deleted'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.inbound.delete.error'), 'danger')
    return false
  }
}

export const getAllOutboundCallConfigurations = async (): Promise<OutboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2[]>(`${outboundCallConfigurationPath}`)
  } catch (error) {
    console.error(error)
    return []
  }
}

export const getOutboundCallConfigurationById = async (id: UUID | string): Promise<OutboundCallConfigurationDTOV2 | undefined> => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2>(`${outboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}

export const createOutboundCallConfiguration = async (
  outboundCallConfiguration: OutboundCallConfigurationDTOV2,
): Promise<OutboundCallConfigurationDTOV2 | undefined> => {
  try {
    const res = await HttpService.postData<OutboundCallConfigurationDTOV2>(outboundCallConfigurationPath, outboundCallConfiguration)
    displayMessage(t('assist.admin.voice.outbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.outbound.create.error'), 'danger')

    return
  }
}

export const updateOutboundCallConfiguration = async (
  outboundCallConfiguration: OutboundCallConfigurationDTOV2,
): Promise<OutboundCallConfigurationDTOV2 | undefined> => {
  try {
    const res = await HttpService.putData<OutboundCallConfigurationDTOV2>(
      `${outboundCallConfigurationPath}/${outboundCallConfiguration.id}`,
      outboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.outbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.outbound.update.error'), 'danger')

    return
  }
}

export const deleteOutboundCallConfiguration = async (id: UUID) => {
  try {
    const res = await HttpService.delete(`${outboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.outbound.deleted'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.outbound.delete.error'), 'danger')
    return false
  }
}

export const getGenesysAgentData = async (): Promise<GenesysAgentDTOV2 | undefined> => {
  const ctiStore = useCtiStore()
  try {
    const { data } = await axios.get(`${ctiStore?.genesysConfiguration?.providerConfiguration?.genesys?.apiUrl}/api/v2/users/me`, {
      params: {
        expand: 'skills',
      },
      headers: {
        Authorization: `Bearer ${ctiStore.token}`,
        'Content-Type': 'application/json',
      },
    })
    return data
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.genesys.fetch.error'), 'danger')
  }
}

export const generateOdigoToken = async () => {
  try {
    return await HttpService.putData(`${odigoConnectUrl}`)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.odigo.token.error'), 'danger')
    throw error
  }
}

export const getOdigoOutboundCallConfigurations = async (): Promise<OutboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2[]>(odigoOutboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getOdigoOutboundCallConfigurationById = async (id: UUID) => {
  try {
    return await HttpService.getData<OutboundCallConfigurationDTOV2>(`${odigoOutboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createOdigoOutboundCallConfiguration = async (odigoOutboundCallConfiguration: OutboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.postData(odigoOutboundCallConfigurationPath, odigoOutboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.outbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.create.error'), 'danger')
    return
  }
}

export const updateOdigoOutboundCallConfiguration = async (odigoOutboundCallConfiguration: OutboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.putData(
      `${odigoOutboundCallConfigurationPath}/${odigoOutboundCallConfiguration.id}`,
      odigoOutboundCallConfiguration,
    )
    displayMessage(t('assist.admin.voice.odigo.outbound.updated'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.update.error'), 'danger')
    return
  }
}
export const deleteOdigoOutboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${odigoOutboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.odigo.outbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.outbound.delete.error'), 'danger')
    return
  }
}
export const getOdigoInboundCallConfigurations = async (): Promise<InboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2[]>(odigoInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getOdigoInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2>(`${odigoInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createOdigoInboundCallConfiguration = async (odigoInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.postData(odigoInboundCallConfigurationPath, odigoInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.create.error'), 'danger')
    return
  }
}

export const updateOdigoInboundCallConfiguration = async (odigoInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    await HttpService.putData(`${odigoInboundCallConfigurationPath}/${odigoInboundCallConfiguration.id}`, odigoInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.odigo.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.update.error'), 'danger')
  }
}
export const deleteOdigoInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${odigoInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.odigo.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.inbound.delete.error'), 'danger')
    return false
  }
}

export const updateOdigoConfiguration = async (odigoConfiguration: OdigoConfigurationDTOV1) => {
  try {
    await HttpService.putData(`${odigoConfigurationsPath}`, odigoConfiguration)
    displayMessage(t('assist.admin.voice.odigo.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.odigo.configuration.update.error'), 'danger')
  }
}

export const getOdigoConfiguration = async (): Promise<OdigoConfigurationDTOV1 | undefined> => {
  try {
    return await HttpService.getData<OdigoConfigurationDTOV1>(`${odigoConfigurationsPath}`)
  } catch (error) {
    console.log(error)
  }
}

export const getOdigoAgentAndStatus = async (): Promise<CtiOdigoAgentAndStatus | null> => {
  try {
    return await HttpService.getData<CtiOdigoAgentAndStatus>(`${odigoAgentAndStatusPath}`)
  } catch (error) {
    console.log(error)
    return null
  }
}

export const httpNewCall = async (callRequestBody: CallRequestBody): Promise<CallDTOV1 | undefined> => {
  try {
    return await HttpService.postData<CallDTOV1>(`/call/api/v1/calls/`, callRequestBody)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.newcall.error'), 'danger')
  }
}

export const httpNewV2Call = async (callRequestBody: CallInteractionDTOV2): Promise<CallInteractionDTOV2 | undefined> => {
  try {
    return await HttpService.postData<CallInteractionDTOV2>(`/call/api/v2/calls/`, callRequestBody)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.voice.newcall.error'), 'danger')
  }
}

export const httpV2PatchCall = async (
  providerConfigurationId: UUID | null,
  callId: string | null,
  callPatch: PatchCallInteractionDTOV2,
): Promise<PatchCallInteractionDTOV2> => {
  return await HttpService.patchData<PatchCallInteractionDTOV2>(`/call/api/v2/calls/${providerConfigurationId}/${callId}`, callPatch)
}

export const sendScreenPopEvent = async (callParams: CallParams, parkPageToken: string): Promise<void> => {
  try {
    return await HttpService.postData<void>(`/cti/ctiScreenPopEvent?parkPageToken=${parkPageToken}`, callParams)
  } catch (error) {
    console.log(error)
    displayMessage(t('assist.admin.cti.screepop.error'), 'danger')
  }
}

export const getVoicebotInboundCallConfigurations = async (): Promise<InboundCallConfigurationDTOV2[]> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2[]>(voicebotInboundCallConfigurationPath)
  } catch (error) {
    console.error(error)
    return []
  }
}
export const getVoicebotInboundCallConfigurationById = async (id: UUID): Promise<InboundCallConfigurationDTOV2 | undefined> => {
  try {
    return await HttpService.getData<InboundCallConfigurationDTOV2>(`${voicebotInboundCallConfigurationPath}/${id}`)
  } catch (error) {
    console.error(error)
    return
  }
}
export const createVoicebotInboundCallConfiguration = async (voicebotInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    const res = await HttpService.postData(voicebotInboundCallConfigurationPath, voicebotInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.inbound.created'), 'success')
    return res
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.create.error'), 'danger')
    return
  }
}

export const updateVoicebotInboundCallConfiguration = async (voicebotInboundCallConfiguration: InboundCallConfigurationDTOV2) => {
  try {
    await HttpService.putData(`${voicebotInboundCallConfigurationPath}/${voicebotInboundCallConfiguration.id}`, voicebotInboundCallConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.inbound.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.update.error'), 'danger')
  }
}
export const deleteVoicebotInboundCallConfiguration = async (id: UUID) => {
  try {
    const deleteResp = await HttpService.delete(`${voicebotInboundCallConfigurationPath}/${id}`)
    displayMessage(t('assist.admin.voice.voicebot.inbound.deleted'), 'success')
    return deleteResp
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.inbound.delete.error'), 'danger')
    return false
  }
}
export const updateVoicebotConfiguration = async (voicebotConfiguration: VoicebotConfigurationDTOV1) => {
  try {
    await HttpService.putData(`${voicebotConfigurationsPath}`, voicebotConfiguration)
    displayMessage(t('assist.admin.voice.voicebot.configuration.updated'), 'success')
  } catch (error) {
    console.error(error)
    displayMessage(t('assist.admin.voice.voicebot.configuration.update.error'), 'danger')
  }
}

export const getVoicebotConfiguration = async (): Promise<VoicebotConfigurationDTOV1 | undefined> => {
  try {
    return await HttpService.getData<VoicebotConfigurationDTOV1>(`${voicebotConfigurationsPath}`)
  } catch (error) {
    console.log(error)
    throw error
  }
}
