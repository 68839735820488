import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import {
  type InteractionTypeData,
  type InteractionData,
  type InteractionIncludeEnum,
  type Interaction,
  type InteractionCreateOrUpdate,
  type InteractionCustom,
  type InteractionCustomNamed,
  type InteractionCustomCreateOrUpdate,
  type InteractionV3,
} from '@/common/types/interaction'

import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'
import { includeTranslationsInItems } from '@/common/helpers/services'

const ServiceV3CustomTypesPath = 'api/v3/interactions/custom-types'

export const getInteractionTypes = async (): Promise<InteractionTypeData[]> => {
  return (await getCachedData<Array<InteractionTypeData>>('interactionTypes', '/api/v1/interactions/types')) || []
}

export const getCustomInteractionTypes = async (): Promise<InteractionCustom[]> => {
  return (await getCachedData<Array<InteractionCustom>>('interactionCustomTypes', ServiceV3CustomTypesPath)) || []
}

export const getInteractions = async (
  caseId?: number,
  ids?: number[],
  page?: number,
  size?: number,
  include?: InteractionIncludeEnum[],
): Promise<InteractionData[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {}
  if (caseId) {
    params.caseId = caseId
  }
  if (ids) {
    params.ids = ids.join()
  }
  if (page) {
    params.page = page
  }
  if (size) {
    params.size = size
  }
  if (include && include.length > 0) {
    params.include = include.join()
  }
  return HttpService.getData<InteractionData[]>(`/api/v4/interactions`, {
    params,
  })
}

export const createInteraction = async (interaction: InteractionCreateOrUpdate) => {
  return HttpService.post<InteractionV3>('api/v3/interactions/', interaction)
}

export const getInteraction = async (id: number) => {
  return HttpService.getData<InteractionV3>(`api/v3/interactions/${id}`)
}

export const updateInteractionSensitiveContent = async (id: number, content: string) => {
  return HttpService.post<InteractionV3>(`/api/v3/interactions/${id}/process-confidential`, {
    content,
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isInteraction(entity: any): entity is Interaction {
  return (entity as Interaction).interactionDate !== undefined
}

export const getAllInteractionsCustomTypes = async (): Promise<InteractionCustomNamed[]> => {
  const sessionStore = useSessionStore()

  return includeTranslationsInItems(
    () =>
      HttpService.getData<Array<InteractionCustom>>(ServiceV3CustomTypesPath, {
        params: {
          fetchAll: true,
        },
      }),
    sessionStore.locale,
    PrefixTranslationsEnum.INTERACTION,
    'id',
  )
}

export const getInteractionCustomType = (id: UUID) => {
  return HttpService.getData<InteractionCustom>(`${ServiceV3CustomTypesPath}/${id}`)
}

export const createInteractionCustomType = async (interaction: InteractionCustomCreateOrUpdate) => {
  return HttpService.post(ServiceV3CustomTypesPath, interaction)
}

export const updateInteractionCustomType = async (id: UUID, interaction: InteractionCustomCreateOrUpdate) => {
  return HttpService.put(`${ServiceV3CustomTypesPath}/${id}`, interaction)
}

export const handleInteractionCustomActivation = (interactionCustom: InteractionCustom, active: boolean) => {
  return HttpService.put(`${ServiceV3CustomTypesPath}/${interactionCustom.id}`, {
    ...interactionCustom,
    active,
  })
}
