export const enum State {
  CONNECTED = 'CONNECTED',
  WORKING = 'WORKING',
  CONSULTING = 'CONSULTING',
  WAITING = 'WAITING',
  CREATING = 'CREATING',
  CHATTING = 'CHATTING',
  WORKING_CHAT = 'WORKING_CHAT',
  PAUSE_1 = 'PAUSE_1',
  PAUSE_2 = 'PAUSE_2',
  PAUSE_3 = 'PAUSE_3',
  PAUSE_4 = 'PAUSE_4',
  PAUSE_5 = 'PAUSE_5',
  PAUSE_6 = 'PAUSE_6',
  PAUSE_7 = 'PAUSE_7',
  PAUSE_8 = 'PAUSE_8',
  PAUSE_9 = 'PAUSE_9',
  DISCONNECTED = 'DISCONNECTED',
}

export const enum Profiles {
  BOT = 'BOT',
}

export type UserLandingPage = {
  landingPagePath: string
}

/**
 * User summary interface
 */
export interface UserSummary {
  id: number
  lastName?: string
  firstName?: string
  login: string
  externalId?: string
  active: boolean
  teamCode: string
  phone?: string
}
