import { type Typology } from '@/common/types/typology'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const serviceRoot = '/api/v1/typologies'

export const getTypologies = async (cacheKey: string): Promise<Typology[]> => {
  return getCachedData<Typology[]>(cacheKey, serviceRoot, {
    params: {
      includeDeactivated: true,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchTypologies = async (cacheKey: string): Promise<any> => {
  return await Promise.all([
    getTypologies(cacheKey),
    loadEnumTranslations(PrefixTranslationsEnum.SPACE),
    loadEnumTranslations(PrefixTranslationsEnum.TYPOLOGY),
  ])
}
