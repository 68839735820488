import { useVoiceStore } from '@/admin/stores/voiceStore'
import { type Menu, StateNames } from '@/admin/types/menu'
import { VoiceProvider } from '@/admin/types/voice'
import {
  faArrowDownToBracket,
  faArrowRightArrowLeft,
  faArrowUpFromBracket,
  faFolders,
  faGear,
  faMagnifyingGlass,
  faSliders,
  faStar,
  faUser,
  faUserHeadset,
  faUsers,
  faWandMagicSparkles,
} from '@fortawesome/pro-regular-svg-icons'
import { ADMIN_ROUTE_PATHS } from '@/admin/types/router'

import { AuthoritiesEnum } from '@/common/types/session'

const menu: Menu = [
  {
    name: 'assist.admin.menu.category.users',
    icon: faUserHeadset,
    children: [
      {
        name: 'assist.admin.menu.entry.profiles',
        accessRight: AuthoritiesEnum.ADMIN_USERPROFILES,
        link: '/assist/admin/userprofiles/',
      },
      {
        name: 'assist.admin.menu.entry.teams',
        accessRight: AuthoritiesEnum.ADMIN_USERGROUPS,
        link: '/assist/admin/groups/',
      },
      {
        name: 'assist.admin.menu.entry.users',
        accessRight: AuthoritiesEnum.ADMIN_USERS,
        link: '/assist/admin/users/',
      },
      {
        name: 'assist.admin.menu.entry.sso',
        accessRight: AuthoritiesEnum.ADMIN_AUTHENTICATION,
        link: ADMIN_ROUTE_PATHS.ADMIN_AUTH_SETTINGS_ROUTE,
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.operations',
    icon: faUsers,
    children: [
      {
        name: 'assist.admin.menu.entry.spaces',
        accessRight: AuthoritiesEnum.ADMIN_SKILLGROUPS,
        link: '/assist/admin/skillGroups/',
      },
      {
        name: 'assist.admin.menu.entry.skills',
        accessRight: AuthoritiesEnum.ADMIN_SKILLS,
        link: '/assist/admin/skills/',
      },
      {
        name: 'assist.admin.menu.entry.screens',
        accessRight: AuthoritiesEnum.ADMIN_SCREENS,
        link: '/assist/admin/screens/',
      },
      {
        name: 'assist.admin.menu.entry.transfer.rules',
        accessRight: AuthoritiesEnum.ADMIN_SKILLS,
        link: '/assist/admin/skills/transferRules',
      },
      {
        name: 'assist.admin.menu.entry.schedules',
        accessRight: AuthoritiesEnum.ADMIN_SCHEDULE,
        link: '/assist/admin/schedules/',
      },
      {
        name: 'assist.admin.menu.entry.holidays',
        accessRight: AuthoritiesEnum.ADMIN_SCHEDULE,
        link: '/assist/admin/holiday/',
      },
      {
        name: 'assist.admin.menu.entry.breaks',
        accessRight: AuthoritiesEnum.ADMIN_PAUSE_TYPES,
        link: '/assist/admin/breaks/',
      },
      {
        name: 'assist.admin.menu.entry.activities',
        accessRight: AuthoritiesEnum.ADMIN_ACTIVITIES,
        link: '/assist/admin/activities',
      },
      {
        name: 'assist.admin.menu.entry.templates',
        accessRight: AuthoritiesEnum.ADMIN_CANNED_RESPONSES,
        link: '/assist/admin/cannedResponses/',
      },
      {
        name: 'assist.admin.menu.entry.customfields',
        accessRight: AuthoritiesEnum.ADMIN_CUSTOM_FIELDS,
        link: '/assist/admin/customfields/',
      },
      {
        name: 'assist.admin.menu.category.contacts',
        children: [
          {
            name: 'assist.admin.menu.entry.addressbook',
            accessRight: AuthoritiesEnum.ADMIN_ADDRESSBOOK,
            link: '/assist/admin/addressBooks/',
          },
          {
            name: 'assist.admin.menu.entry.contact.lists',
            accessRight: AuthoritiesEnum.ADMIN_CONTACT_LISTS,
            link: '/assist/admin/contacts/',
          },
        ],
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.channels',
    icon: faSliders,
    children: [
      {
        name: 'assist.admin.menu.entry.channels',
        accessRight: AuthoritiesEnum.ADMIN_CHANNELS,
        link: '/assist/admin/channels/',
      },
      {
        name: 'assist.admin.menu.entry.channels.groups',
        accessRight: AuthoritiesEnum.ADMIN_ENGAGE_CHANNELGROUP,
        link: '/assist/admin/channelGroups/',
      },
      {
        name: 'assist.admin.menu.category.chat',
        children: [
          {
            name: 'assist.admin.menu.entry.chat.css',
            accessRight: AuthoritiesEnum.ADMIN_CSS_SOURCE,
            link: '/assist/admin/chat/cssSources/',
          },
          {
            name: 'assist.admin.menu.entry.chat.config',
            accessRight: AuthoritiesEnum.ADMIN_CSS_SOURCE,
            link: '/assist/admin/chat/configurations/',
          },
          {
            name: 'assist.admin.menu.entry.chat.sdk',
            accessRight: AuthoritiesEnum.ADMIN_CHAT,
            link: '/assist/admin/chat/sdk/',
          },
          {
            name: 'assist.admin.menu.entry.chat.teaser',
            accessRight: AuthoritiesEnum.ADMIN_CSS_SOURCE,
            link: '/assist/admin/chat/teasers/',
          },
          {
            name: 'assist.admin.menu.entry.chat.fullframe',
            accessRight: AuthoritiesEnum.ADMIN_CSS_SOURCE,
            link: '/assist/admin/chat/fullframes/',
          },
        ],
      },
      {
        name: 'assist.admin.menu.category.foundever.toolbar',
        children: [
          {
            name: 'assist.admin.menu.entry.vdn',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/cti/vdn/',
          },
          {
            name: 'assist.admin.menu.entry.cti.providers',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/cti/providers/',
          },
          {
            name: 'assist.admin.menu.entry.callnumbers',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/cti/outboundCallNumbers/',
          },
        ],
      },
      {
        name: 'assist.admin.menu.category.voice',
        loader: () => useVoiceStore().initVoice(),
        children: [
          {
            name: 'Axialys',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/voice/axialys-configuration/',
            stateName: StateNames.CTI_PROVIDER,
            state: VoiceProvider.AXIALYS,
          },
          {
            name: 'Odigo',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/voice/odigo-configuration/',
            stateName: StateNames.CTI_PROVIDER,
            state: `${VoiceProvider.ODIGO}`,
          },
          {
            name: 'Genesys',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/voice/genesys-configuration/',
            stateName: StateNames.CTI_PROVIDER,
            state: `${VoiceProvider.GENESYS}`,
          },
          {
            name: 'Voicebot',
            accessRight: AuthoritiesEnum.ADMIN_CTI,
            link: '/assist/admin/voice/voicebot-configuration/',
            stateName: StateNames.CTI_PROVIDER,
            state: `${VoiceProvider.VOICEBOT}`,
            alwaysActive: true,
          },
        ],
      },
      {
        name: 'assist.admin.menu.category.email',
        children: [
          {
            name: 'assist.admin.menu.entry.mailboxes',
            accessRight: AuthoritiesEnum.ADMIN_MAIL_MAILBOX,
            link: '/assist/admin/mail/mailboxes/',
          },
          {
            name: 'assist.admin.menu.entry.smtp.servers',
            accessRight: AuthoritiesEnum.ADMIN_MAIL_SMTP,
            link: '/assist/admin/mail/smtp/',
          },
          {
            name: 'assist.admin.menu.entry.envelopes',
            accessRight: AuthoritiesEnum.ADMIN_MAIL_ENVELOPE,
            link: '/assist/admin/mail/envelopes/',
          },
          {
            name: 'assist.admin.menu.entry.deliveryreceipts',
            accessRight: AuthoritiesEnum.ADMIN_MAIL_ACKNOWLEDGMENTS,
            link: '/assist/admin/mail/acknowledgments/',
          },
          {
            name: 'assist.admin.menu.entry.email.routing.rules',
            accessRight: AuthoritiesEnum.ADMIN_RULES,
            link: '/assist/admin/mail/routing/',
          },
        ],
      },
      {
        name: 'assist.admin.menu.category.sms',
        children: [
          {
            name: 'assist.admin.menu.entry.sms.providers',
            accessRight: AuthoritiesEnum.ADMIN_SMS_PROVIDER,
            link: '/assist/admin/sms/providers/',
          },
          {
            name: 'assist.admin.menu.entry.sms.senders',
            accessRight: AuthoritiesEnum.ADMIN_SMS_SENDER,
            link: '/assist/admin/sms/senders/',
          },
          {
            name: 'assist.admin.menu.entry.vonage',
            accessRight: AuthoritiesEnum.ADMIN_SMS_SENDER,
            link: '/assist/admin/vonage/configurations',
          },
        ],
      },
      {
        name: 'assist.admin.menu.entry.selfservice',
        accessRight: AuthoritiesEnum.ADMIN_SELFCARE_PORTAL,
        link: '/assist/admin/selfcare/portal/',
      },
      {
        name: 'assist.admin.menu.category.social',
        children: [
          {
            name: 'assist.admin.menu.entry.facebook',
            accessRight: AuthoritiesEnum.ADMIN_SOCIAL_FACEBOOK,
            link: '/assist/admin/social/facebook/',
          },
          {
            name: 'assist.admin.menu.entry.whatsapp',
            accessRight: AuthoritiesEnum.ADMIN_SOCIAL_WHATSAPP,
            link: '/assist/admin/social/whatsApp/',
          },
          {
            name: 'assist.admin.menu.entry.instagram',
            accessRight: AuthoritiesEnum.ADMIN_SOCIAL_INSTAGRAM,
            link: '/assist/admin/social/instagram/',
          },
        ],
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.cases',
    icon: faFolders,
    children: [
      {
        name: 'assist.admin.menu.entry.search.cases.criteria',
        accessRight: AuthoritiesEnum.ADMIN_TICKETS_SEARCH_SCREEN,
        link: '/assist/admin/ticketCriteria/',
      },
      {
        name: 'assist.admin.menu.entry.search.cases.results',
        accessRight: AuthoritiesEnum.ADMIN_TICKETS_LISTING_SCREEN,
        link: '/assist/admin/ticketListing/',
      },
      {
        name: 'assist.admin.menu.entry.contactdrivers',
        accessRight: AuthoritiesEnum.ADMIN_TYPOLOGIES,
        link: '/assist/admin/typologies/',
      },
      {
        name: 'assist.admin.menu.entry.reasons',
        accessRight: AuthoritiesEnum.ADMIN_REASONS,
        link: '/assist/admin/reasons',
      },
      {
        name: 'assist.admin.menu.entry.priorities',
        accessRight: AuthoritiesEnum.ADMIN_PRIORITIES,
        link: '/assist/admin/priority/',
      },
      {
        name: 'assist.admin.menu.entry.emergencies',
        accessRight: AuthoritiesEnum.ADMIN_EMERGENCIES,
        link: '/assist/admin/emergency/',
      },
      {
        name: 'assist.admin.menu.entry.products',
        accessRight: AuthoritiesEnum.ADMIN_PRODUCTS,
        link: '/assist/admin/products/',
      },
      {
        name: 'assist.admin.menu.entry.polarities',
        accessRight: AuthoritiesEnum.ADMIN_POLARITY_SENTIMENT,
        link: '/assist/admin/polaritySentiments/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.customers',
    icon: faUser,
    children: [
      {
        name: 'assist.admin.menu.entry.customer.record',
        accessRight: AuthoritiesEnum.ADMIN_SCREENS_CUSTOMER,
        link: '/assist/admin/screensCustomer/',
      },
      {
        name: 'assist.admin.menu.entry.search.customer.results',
        accessRight: AuthoritiesEnum.ADMIN_CLIENTS_LISTING_SCREEN,
        link: '/assist/admin/clientListing/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.interactions',
    icon: faArrowRightArrowLeft,
    children: [
      {
        name: 'assist.admin.menu.entry.interactions.list',
        accessRight: AuthoritiesEnum.ADMIN_INTERACTIONS_LISTING_SCREEN,
        link: '/assist/admin/interactions/list',
      },
      {
        name: 'assist.admin.menu.entry.interactions.customtypes',
        accessRight: AuthoritiesEnum.ADMIN_EXTERNAL_INTERACTION_TYPES,
        link: '/assist/admin/interactions/custom-types/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.search',
    icon: faMagnifyingGlass,
    children: [
      {
        name: 'assist.admin.menu.entry.search.config',
        accessRight: AuthoritiesEnum.ADMIN_SEARCH_CRITERIA,
        link: '/assist/admin/search',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.export',
    icon: faArrowUpFromBracket,
    children: [
      {
        name: 'assist.admin.menu.entry.export.tickets',
        accessRight: AuthoritiesEnum.ADMIN_TICKET_EXPORT_DEFINITION,
        link: '/assist/admin/ticketExportDefinition',
      },
      {
        name: 'assist.admin.menu.entry.export.customers',
        accessRight: AuthoritiesEnum.ADMIN_CUSTOMER_EXPORT_DEFINITION,
        link: '/assist/admin/customerExportDefinition',
      },
      {
        name: 'assist.admin.menu.entry.export.interactions',
        accessRight: AuthoritiesEnum.ADMIN_INTERACTION_EXPORT_DEFINITION,
        link: '/assist/admin/interactionExportDefinition',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.import',
    icon: faArrowDownToBracket,
    children: [
      {
        name: 'assist.admin.menu.entry.import.files',
        accessRight: AuthoritiesEnum.ADMIN_FILE_DEFINITIONS,
        link: '/assist/admin/fileDefinitions/',
      },
      {
        name: 'assist.admin.menu.entry.import.rules',
        accessRight: AuthoritiesEnum.ADMIN_FILE_RULES,
        link: '/assist/admin/fileRules/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.automation',
    icon: faWandMagicSparkles,
    children: [
      {
        name: 'assist.admin.menu.entry.jobs',
        accessRight: AuthoritiesEnum.ADMIN_SYSTEM_PARAM,
        link: '/assist/admin/quartzjobs/',
      },
      {
        name: 'assist.admin.menu.entry.bots',
        accessRight: AuthoritiesEnum.ADMIN_BOT,
        link: '/assist/admin/bot',
      },
      {
        name: 'assist.admin.menu.entry.triggers',
        accessRight: AuthoritiesEnum.ADMIN_TRIGGER,
        link: '/assist/admin/trigger/',
      },
      {
        name: 'assist.admin.menu.entry.notifications',
        accessRight: AuthoritiesEnum.ADMIN_NOTIFICATIONS,
        link: '/assist/admin/notifications/',
      },
      {
        name: 'assist.admin.menu.entry.archiving',
        accessRight: AuthoritiesEnum.ADMIN_TICKET_ARCHIVING,
        link: '/assist/admin/ticketArchiving/',
      },
      {
        name: 'assist.admin.menu.entry.distributionrules',
        accessRight: AuthoritiesEnum.ADMIN_RULES,
        link: '/assist/admin/rules/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.performance',
    icon: faStar,
    children: [
      {
        name: 'assist.admin.menu.category.satisfaction',
        children: [
          {
            name: 'assist.admin.menu.entry.surveys',
            accessRight: AuthoritiesEnum.ADMIN_SATISFACTION_SURVEY,
            link: '/assist/admin/satisfaction/surveys',
          },
          {
            name: 'assist.admin.menu.entry.campaigns',
            accessRight: AuthoritiesEnum.ADMIN_SATISFACTION,
            link: '/assist/admin/satisfaction/campaigns',
          },
        ],
      },
      {
        name: 'assist.admin.menu.entry.quality',
        accessRight: AuthoritiesEnum.ADMIN_QUALITY_GRID,
        link: '/assist/admin/quality/grids',
      },
      {
        name: 'assist.admin.menu.entry.scripts',
        accessRight: AuthoritiesEnum.ADMIN_SATISFACTION_SCRIPT,
        link: '/assist/admin/satisfaction/scripts/',
      },
    ],
  },
  {
    name: 'assist.admin.menu.category.settings',
    icon: faGear,
    children: [
      {
        name: 'assist.admin.menu.entry.settings.global',
        accessRight: AuthoritiesEnum.ADMIN_SYSTEM_PARAM,
        link: '/assist/admin/params/',
      },
      {
        name: 'assist.admin.menu.entry.settings.regional',
        accessRight: AuthoritiesEnum.ADMIN_REGIONAL_SETTINGS,
        link: '/assist/admin/regional/',
      },
      {
        name: 'assist.admin.menu.entry.customerids',
        accessRight: AuthoritiesEnum.ADMIN_SYSTEM_PARAM,
        link: '/assist/admin/settings/customer/view',
      },
      {
        name: 'assist.admin.menu.entry.translations',
        accessRight: AuthoritiesEnum.ADMIN_TRANSLATION,
        link: '/assist/admin/trans/',
      },
      {
        name: 'assist.admin.menu.entry.resources',
        accessRight: AuthoritiesEnum.ADMIN_RESOURCES,
        link: '/assist/admin/resources/',
      },
      {
        name: 'assist.admin.menu.entry.workflow.transitions',
        accessRight: AuthoritiesEnum.ADMIN_WORKFLOW_TRANSITIONS,
        link: '/assist/admin/wkfwTransition/',
      },
      {
        name: 'assist.admin.menu.entry.lab',
        accessRight: AuthoritiesEnum.ADMIN_INNSO_LAB,
        link: '/assist/admin/lab/',
      },
      {
        name: 'assist.admin.menu.entry.logos',
        accessRight: AuthoritiesEnum.ADMIN_LOGOS,
        link: '/assist/admin/logo/',
      },
      {
        name: 'assist.admin.menu.entry.foundeverGPT',
        accessRight: AuthoritiesEnum.ADMIN_SYSTEM_PARAM,
        link: '/assist/admin/foundeverGPT/',
      },
      {
        name: 'assist.admin.menu.entry.versions',
        accessRight: AuthoritiesEnum.ADMIN_INNSO_VERSIONS,
        link: '/assist/admin/versions/',
      },
      {
        name: 'assist.admin.link.knowledge_base',
        accessRight: AuthoritiesEnum.ADMIN_KNOWLEDGE,
        link: ADMIN_ROUTE_PATHS.ADMIN_SETTINGS_KB_ROUTE,
      },
    ],
  },
]

export default menu
