import type { Team } from '@/common/types/teams.ts'
import { loadEnumTranslations } from './LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const serviceRoot = '/api/v1/teams'

export const getTeams = async (cacheKey: string): Promise<Team[]> => {
  return getCachedData<Team[]>(cacheKey, serviceRoot, {
    params: {
      includeDeactivated: true,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchTeams = async (cacheKey: string): Promise<any> => {
  return await Promise.all([getTeams(cacheKey), loadEnumTranslations(PrefixTranslationsEnum.USERGROUP)])
}
