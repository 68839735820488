// V1
export type SkillDataV1 = {
  id: number
  code: string
  group: string
  spaceName: string
  default: boolean
  active: boolean
}

// V2
export type Skill = {
  active: boolean
  code: string
  default: boolean
  scheduleCode: string
  spaceCode: string
  groupName?: string
  targetProcessingTime?: number
}

export enum SkillsIncludeEnum {
  PRODUCTIVITY = 'PRODUCTIVITY',
}
