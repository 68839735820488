import { type CtiInteractionTypeEnum, type CtiStatus } from '@/cti/types/cti'
import { type Ref } from 'vue'

export enum VoiceProvider {
  'CTI_INHOUSE' = 'cti-inhouse',
  'AXIALYS' = 'axialys',
  'ODIGO' = 'odigo',
  'GENESYS' = 'genesys',
  'VOICEBOT' = 'voicebot',
  'NONE' = 'none',
}

export enum VoiceConfigurationTabEnum {
  'GENERAL' = 'general',
  'INBOUND' = 'inbound',
  'OUTBOUND' = 'outbound',
}

export enum ProviderConnectionType {
  'TOKEN' = 'token',
  'MANUAL' = 'manual',
  'SSO' = 'SSO',
}

export enum ProviderUIType {
  'FRAMED' = 'framed',
}

export enum LandingPageEnum {
  'TICKET_HANDLING_SCREEN' = 'ticket-handling-screen',
  'SEARCH_CUSTOMER_SCREEN' = 'search-customer-screen',
}

export enum CustomerPhoneFieldEnum {
  'HOMEPHONE' = 'homePhone',
  'WORKPHONE' = 'workPhone',
  'MOBILEPHONE' = 'mobilePhone',
  'OTHERPHONE' = 'otherPhone',
}

export type CallConfig = {
  configType: VoiceConfigurationTabEnum
  callConfig?: InboundCallConfigurationDTOV2 | OutboundCallConfigurationDTOV2
  configId?: UUID
}

export type VoiceConfigurationDTOV1 = {
  activeProvider: VoiceProvider
}

export type ProviderGeneralConfigurationDTOV2 = {
  id: UUID | null
  provider: VoiceProvider
  providerConfiguration?: ProviderSpecificConfiguration | null
  connectionType: ProviderConnectionType | null
  connection?: {
    login?: string
    password?: string
  }
  uiIntegrationType?: ProviderUIType
  landingPage: LandingPageEnum | null
  fallbackSkill: string | null
  iframeUrl?: string
  creationCustomerWhenNotFoundEnabled: boolean
  attachLatestCustomerWhenMultipleFoundEnabled?: boolean
  creationCustomerPhoneFieldName: CustomerPhoneFieldEnum | null
}

type VoiceProviderConfigurationDTOV1 = {
  login?: string
  password?: string
  commonConfiguration: CommonConfigurationDTOV1
}

type ProviderSpecificConfiguration = {
  genesys?: GenesysIFrameConfiguration
  odigo?: OdigoConfigurationDTOV1
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface AxialysConfigurationDTOV1 extends VoiceProviderConfigurationDTOV1 {}

export interface GenesysIFrameConfiguration {
  clientId: string
  apiUrl: string
  loginUrl: string
  iframeUrl: string
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface VoicebotConfigurationDTOV1 extends VoiceProviderConfigurationDTOV1 {}

export interface OdigoConfigurationDTOV1 extends VoiceProviderConfigurationDTOV1 {
  supervisorIdentifier: string
  appUid: string
  productUid: string
  apiUrl: string
}

export type CommonConfigurationDTOV1 = {
  connectionType: ProviderConnectionType
  uiIntegrationType: ProviderUIType
  needOfGroupId: boolean
  phoneCallLinkSupported: boolean
  landingPage: LandingPageEnum
  fallbackSkill: string
  iframeUrl: string
  creationCustomerWhenNotFoundEnabled: boolean
  attachLatestCustomerWhenMultipleFoundEnabled: boolean
  creationCustomerPhoneFieldName: CustomerPhoneFieldEnum
}

export type InboundCallConfigurationDTOV2 = {
  id?: UUID
  providerConfiguration: UUID
  summary?: string
  code: string
  phoneNumber?: string
  skill: string
  active?: boolean
  space?: number
}

export type OutboundCallConfigurationDTOV2 = {
  id?: UUID
  providerConfiguration: UUID
  summary?: string
  code: string
  phoneNumber?: string
  space?: number
  defaultSkill: string
  requiredSkills?: Array<string>
  active: boolean
}

export interface CallDTOV1 {
  callId: string
  provider: VoiceProvider
  groupId: string | null
  recordingLink: string | null
  serviceNumber: string | null
  callConfiguration: UUID
  customer: VoiceCustomer
  type: CtiInteractionTypeEnum
  ticketId: number
}

export interface CallInteractionDTOV2 {
  callId?: string
  providerConfiguration: UUID
  callConfiguration?: UUID
  customer?: VoiceCustomer
  agent?: VoiceAgent
  escalateToLiveAgent?: boolean
  duration?: string
  messages?: CallMessage[]
  caseId?: number
  type: CtiInteractionTypeEnum
}

export interface CallMessage {
  text: string
  type: CtiInteractionTypeEnum
}

export interface CallRequestBody {
  callId: string | null
  provider: VoiceProvider
  serviceNumber: string | null
  callConfiguration: UUID | null | undefined
  groupId: string | null
  customer: VoiceCustomer
  type: CtiInteractionTypeEnum
  agent: VoiceAgent
  ticketId?: number | null
}

export interface OutboundCallRequestBody {
  outboundCallNumberId: UUID
  phoneNumber: string
  ticketId: number | null
}

export interface CallPatch {
  provider: string
  duration: CallDuration
}

export interface PatchCallInteractionDTOV2 {
  recordingLink: string
  duration: CallDuration
}

export type CallDuration = `PT${number | ''}${number | ''}${number | ''}${number | ''}${number | ''}${number}S`

export interface VoiceCustomer {
  customerNumber: string | null
  userToUserInformation?: string
  externalReference?: string | null
  customerId?: number | null
  foundMultipleCustomers?: boolean | null
}

export interface VoiceAgent {
  ctiLogin?: string | null
  extension?: string
  desktopLogin?: string
}

export interface AgentModel {
  providerLogin?: string | null
  extension?: string
  desktopLogin?: string
  status?: CtiStatus
}

export type ModalConfirm = {
  configType: string
  status: Ref<boolean>
  config: InboundCallConfigurationDTOV2 | OutboundCallConfigurationDTOV2
}

export interface IFrameStyle {
  narrowWidth: string
  wideWidth: string
  height: string
  innerPadding: string
}
