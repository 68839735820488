import HttpService from '@/common/services/HttpService'
import { type SearchConfig } from '@/admin/types/search'

/**
 * get search config by UUID
 * @param id config id
 * @returns search config
 */
export const getSearchConfigs = async (includeDeactivated: boolean = false): Promise<SearchConfig[]> => {
  return HttpService.getData<SearchConfig[]>('/search/api/v1/config', {
    params: {
      includeDeactivated,
    },
  })
}

/**
 * get search config by UUID
 * @param id config id
 * @returns search config
 */
export const getSearchConfig = async (id: UUID): Promise<SearchConfig> => {
  return HttpService.getData<SearchConfig>('/search/api/v1/config/' + id)
}

/**
 * post search config
 * @param data config data
 */
export const createSearchConfig = async (data: SearchConfig): Promise<SearchConfig> => {
  return HttpService.postData('/search/api/v1/config', data)
}

/**
 * put search config by id
 * @param id config id
 * @param data config data
 */
export const updateSearchConfig = async (id: UUID, data: SearchConfig): Promise<SearchConfig> => {
  return HttpService.putData('/search/api/v1/config/' + id, data)
}

/**
 * Handle search config activation
 */
export const handleSearchActivation = (id: UUID, active: boolean) => {
  return HttpService.patch(`/search/api/v1/config/${id}`, {
    activeToSet: active,
  })
}
