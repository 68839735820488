const langList = import.meta.glob('/node_modules/quasar/lang/*.mjs')
import { type FieldLocalizedMessage, type Locale } from '@/admin/types/locale'
import { type LocalizedMessageV2, type LocalizedMessage } from '@/common/types/i18n'
import { Quasar } from 'quasar'
export default function useLocaleUtils() {
  async function changeQuasarLanguage(locale: string) {
    const localeQuasarFormat = locale.replace('_', '-')
    try {
      for (const targetLocale of [localeQuasarFormat, localeQuasarFormat.substring(0, 2)]) {
        const langModule = `/node_modules/quasar/lang/${targetLocale}.mjs`
        if (langModule in langList) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const lang: any = await langList[langModule]()
          Quasar.lang.set(lang.default)
          break
        }
      }
    } catch {
      console.warn("User language doesn't exist in Quasar. Loaded 'en-US' language by default")
    }
  }

  function getBrowserLocal(): string {
    // get default navigator local matching the iso format : [lang]_[country]
    for (const language of navigator.languages) {
      if (language.match(/^[a-z]{2}-[A-Z]{2}$/)) {
        return language
      }
    }
    return navigator.language
  }

  return { changeQuasarLanguage, getBrowserLocal }
}

export function localizedFieldsToI18nApiMessages(translations: FieldLocalizedMessage[], key: string): LocalizedMessageV2[] {
  return translations.map((f) => {
    return {
      locale: f.locale,
      key,
      message: f.message,
    }
  })
}

export const getFieldLocalizedMessage = (translationsNames: LocalizedMessage[], locales: Locale[]): FieldLocalizedMessage[] => {
  const list: FieldLocalizedMessage[] = []
  for (const locale of locales) {
    list.push({
      name: 'name',
      locale: locale.code,
      message: translationsNames.find((f) => f.locale === locale.code)?.message ?? '',
      displayOrder: 1,
    })
  }
  return list
}
