import { type ComponentPublicInstance } from 'vue'
import { type EntityEnum } from '@/common/types/general'

/**
 * All field types
 */
export const enum FieldTypeEnum {
  TEXT = 'TEXT',
  TEXTAREA = 'TEXTAREA',
  EMAIL = 'EMAIL',
  NUMBER = 'NUMBER',
  LOCAL_DATE = 'LOCAL_DATE',
  TIMESTAMP = 'TIMESTAMP',
  SELECT_LIST_SINGLE = 'SELECT_LIST_SINGLE',
  SELECT_LIST_MULTIPLE = 'SELECT_LIST_MULTIPLE',
  CHECKBOX_SINGLE = 'CHECKBOX_SINGLE',
  CHECKBOX_MULTIPLE = 'CHECKBOX_MULTIPLE',
  RADIO = 'RADIO',
  TREE_SINGLE = 'TREE_SINGLE',
  TREE_MULTIPLE = 'TREE_MULTIPLE',
  TREE_SINGLE_ASCENDING = 'TREE_SINGLE_ASCENDING',
  SELECT_ENTITY_SINGLE = 'SELECT_ENTITY_SINGLE',
}

/**
 * All entity fields code
 * To sort when you add a new code : https://miniwebtool.com/sort-lines-alphabetically/
 */
export const enum EntityFieldCodeEnum {
  // TICKET
  ACTION_REASON = 'ACTION_REASON',
  ASSIGNEE = 'ASSIGNEE',
  ASSIGNEE_EXTERNAL_ID = 'ASSIGNEE_EXTERNAL_ID',
  ASSIGNEE_LOGIN = 'ASSIGNEE_LOGIN',
  ASSIGNEE_PHONE = 'ASSIGNEE_PHONE',
  ASSIGNEE_SITE = 'ASSIGNEE_SITE',
  ASSIGNEE_USERGROUP_NAME = 'ASSIGNEE_USERGROUP_NAME',
  CREATION_CHANNEL = 'CREATION_CHANNEL',
  CREATION_DATE = 'CREATION_DATE',
  CREATION_SCREEN = 'CREATION_SCREEN',
  CREATION_SUMMARY = 'CREATION_SUMMARY',
  CREATION_USER = 'CREATION_USER',
  CREATION_USER_SITE = 'CREATION_USER_SITE',
  CURRENT_CHANNEL = 'CURRENT_CHANNEL',
  CURRENT_SKILL = 'CURRENT_SKILL',
  DUE_DATE = 'DUE_DATE',
  DUE_DATE_ALERT = 'DUE_DATE_ALERT',
  EMERGENCY = 'EMERGENCY',
  EXTERNAL_REF = 'EXTERNAL_REF',
  FIRST_ASSIGNEE = 'FIRST_ASSIGNEE',
  FIRST_CLAIM_DATE = 'FIRST_CLAIM_DATE',
  FIRST_INBOUND_DATE = 'FIRST_INBOUND_DATE',
  FIRST_INBOUND_STATUS = 'FIRST_INBOUND_STATUS',
  FIRST_INBOUND_TYPE = 'FIRST_INBOUND_TYPE',
  FIRST_INTERACTION_DIRECTION = 'FIRST_INTERACTION_DIRECTION',
  FIRST_OUTBOUND_DATE = 'FIRST_OUTBOUND_DATE',
  FIRST_OUTBOUND_STATUS = 'FIRST_OUTBOUND_STATUS',
  FIRST_OUTBOUND_TYPE = 'FIRST_OUTBOUND_TYPE',
  HAS_ATTACHMENTS = 'HAS_ATTACHMENTS',
  IMPORT_ACTION_TYPE = 'IMPORT_ACTION_TYPE',
  IMPORT_DATE = 'IMPORT_DATE',
  IMPORT_ID = 'IMPORT_ID',
  IMPORT_USER = 'IMPORT_USER',
  INITIAL_SKILL = 'INITIAL_SKILL',
  LAST_ACTION_REASON = 'LAST_ACTION_REASON',
  LAST_ACTION_REASON_NOT_EMPTY = 'LAST_ACTION_REASON_NOT_EMPTY',
  LAST_ACTION_TYPE = 'LAST_ACTION_TYPE',
  LAST_ACTION_TYPE_REASON_NOT_EMPTY = 'LAST_ACTION_TYPE_REASON_NOT_EMPTY',
  LAST_ASSIGNEE = 'LAST_ASSIGNEE',
  LAST_COMMENT = 'LAST_COMMENT',
  LAST_EVENT_DATE = 'LAST_EVENT_DATE',
  LAST_INTERACTION_DATE = 'LAST_INTERACTION_DATE',
  LAST_INTERACTION_WITH = 'LAST_INTERACTION_WITH',
  LAST_REOPEN_ACTION = 'LAST_REOPEN_ACTION',
  LAST_VALIDATION_ACTION_COMMENT = 'LAST_VALIDATION_ACTION_COMMENT',
  LAST_VALIDATION_ACTION_DATE = 'LAST_VALIDATION_ACTION_DATE',
  LAST_VALIDATION_ACTION_TYPE_NAME = 'LAST_VALIDATION_ACTION_TYPE_NAME',
  LAST_VALIDATION_ACTION_USER = 'LAST_VALIDATION_ACTION_USER',
  NB_REOPEN_ACTIONS = 'NB_REOPEN_ACTIONS',
  ONCE_AND_DONE = 'ONCE_AND_DONE',
  OTHER_ACTION_TYPE = 'OTHER_ACTION_TYPE',
  OTHER_ACTION_DATE = 'OTHER_ACTION_DATE',
  OTHER_ACTION_USER = 'OTHER_ACTION_USER',
  PRIORITY = 'PRIORITY',
  PRODUCT = 'PRODUCT',
  REASON_LAST_ACTION_ONLY = 'REASON_LAST_ACTION_ONLY',
  SKILL_TARGET_PROCESSING_TIME = 'SKILL_TARGET_PROCESSING_TIME',
  TICKET_ID = 'TICKET_ID',
  TICKET_MAILBOX = 'TICKET_MAILBOX',
  TICKET_SLA = 'TICKET_SLA',
  TICKET_STATUS = 'TICKET_STATUS',
  TICKET_USERGROUP = 'TICKET_USERGROUP',
  TYPOLOGY = 'TYPOLOGY',
  VALIDATION_STATUS = 'VALIDATION_STATUS',
  WAKE_UP_DATE = 'WAKE_UP_DATE',
  // CUSTOMER
  CUSTOMER_ADDRESS = 'CUSTOMER_ADDRESS',
  CUSTOMER_COMMENTS = 'CUSTOMER_COMMENTS',
  CUSTOMER_COMPANYNAME = 'CUSTOMER_COMPANYNAME',
  CUSTOMER_CREATIONDATE = 'CUSTOMER_CREATIONDATE',
  CUSTOMER_DELETEDATE = 'CUSTOMER_DELETEDATE',
  CUSTOMER_EMAIL = 'CUSTOMER_EMAIL',
  CUSTOMER_FIRSTNAME = 'CUSTOMER_FIRSTNAME',
  CUSTOMER_ID = 'CUSTOMER_ID',
  CUSTOMER_INCLUDE_DELETED = 'CUSTOMER_INCLUDE_DELETED',
  CUSTOMER_LASTNAME = 'CUSTOMER_LASTNAME',
  CUSTOMER_LAST_MODIFIED_DATE = 'CUSTOMER_LAST_MODIFIED_DATE',
  CUSTOMER_PHONE = 'CUSTOMER_PHONE',
  CUSTOMER_REF = 'CUSTOMER_REF',
  CUSTOMER_VIP = 'CUSTOMER_VIP',
  // INTERACTION
  INTERACTION_ID = 'INTERACTION_ID',
  INTERACTION_TYPE = 'INTERACTION_TYPE',
  INTERACTION_DATE = 'INTERACTION_DATE',
  INTERACTION_STATUS = 'INTERACTION_STATUS',
  INTERACTION_CONTACT = 'INTERACTION_CONTACT',
  INTERACTION_CONTENT = 'INTERACTION_CONTENT',
  INTERACTION_USER = 'INTERACTION_USER',
  INTERACTION_SLA = 'INTERACTION_SLA',
  INTERACTION_SUMMARY = 'INTERACTION_SUMMARY',
  INTERACTION_SENT_FROM = 'INTERACTION_SENT_FROM',
  INTERACTION_HAS_ATTACHMENTS = 'INTERACTION_HAS_ATTACHMENTS',
}

/**
 * All contexts
 */
export const enum FieldContextEnum {
  CUSTOMER_EXPORT = 'CUSTOMER_EXPORT',
  EMAIL_RULE = 'EMAIL_RULE',
  CUSTOMER_IDENTIFICATION = 'CUSTOMER_IDENTIFICATION',
  TICKET_EXPORT = 'TICKET_EXPORT',
  TICKET_LIST = 'TICKET_LIST',
  CUSTOMER_LIST = 'CUSTOMER_LIST',
  CUSTOMER_CARD = 'CUSTOMER_CARD',
  TICKET_IMPORT = 'TICKET_IMPORT',
  INTERACTION_TREATMENT = 'INTERACTION_TREATMENT',
  TICKET_CREATION = 'TICKET_CREATION',
  TICKET_TREATMENT = 'TICKET_TREATMENT',
  TICKET_ARCHIVING = 'TICKET_ARCHIVING',
  SEARCH = 'SEARCH',
  CUSTOMER_DATA_DELETION = 'CUSTOMER_DATA_DELETION',
  CUSTOMER_DATA_DELETION_CUSTOM_DATE_FIELD = 'CUSTOMER_DATA_DELETION_CUSTOM_DATE_FIELD',
  INTERACTION_LIST = 'INTERACTION_LIST',
  INTERACTION_EXPORT = 'INTERACTION_EXPORT',
}

/**
 * Model of field we get from server in assist-model
 */
export type FieldData = {
  code: string
  entity: EntityEnum
  entityProperty: string | null
  modelProperty: string | null
  type: FieldTypeEnum
  options?: string[]
  custom: boolean
  context: Array<FieldContextEnum>
}

export type FieldsDataList = {
  [key: string]: CustomFieldData
}

export type BasicField = FieldData & {
  name: string
}

/**
 * Model of custom field we get from server in assist-model
 */
export type CustomFieldData = {
  id: number
  code: string
  property: string
  entity: EntityEnum
  type: FieldTypeEnum
  active: boolean
  internal: boolean
  maxlength?: number
  regex?: string
  treeCascade: boolean
  treeParentSelectable: boolean
  nowButtonAvailable?: boolean
  options: OptionCustomFieldData[]
}

/**
 * Model of option of custom field we get from server in assist-model
 */
export type OptionCustomFieldData = {
  id: number
  code: string
  parent?: string
  name: string
  description?: string
  displayOrder: number
  defaultValue: boolean
  active: boolean
}

export type CustomField = CustomFieldData & {
  name: string
  description: string
  label: string
  shortName: string
}

export const enum ReadModeEnum {
  CRITERIA = 'CRITERIA',
  TABLE = 'TABLE',
}

/**
 * Component type. It allows to use dynamic vue components
 */
export type Component = {
  source: () => Promise<ComponentPublicInstance>
}

/**
 * Wrapper which contain the most important attributes to use dynamic fields in qualification, search, etc...
 */
export interface FieldWrapper {
  field: BasicField
  component: Component
  readonly defaultValue: string[]
  currentValue: string[]
}

export interface CustomerFieldSettings {
  fieldId: number
  code?: string
  displayOrder: number
  fieldType: FieldTypeEnum
}

export type CustomerFieldSettingsToken = {
  fields: { selected: CustomerFieldSettings[] }
}

export enum CriteriaCategoryKey {
  TICKET_ENTITY_FIELD = 'TICKET_ENTITY_FIELD',
  TICKET_CUSTOM_FIELD = 'TICKET_CUSTOM_FIELD',
  CUSTOMER_ENTITY_FIELD = 'CUSTOMER_ENTITY_FIELD',
  CUSTOMER_CUSTOM_FIELD = 'CUSTOMER_CUSTOM_FIELD',
  INTERACTION_ENTITY_FIELD = 'INTERACTION_ENTITY_FIELD',
  INTERACTION_CUSTOM_FIELD = 'INTERACTION_CUSTOM_FIELD',
}

export const CUSTOMFIELD_CODE_PREFIX = 'CF_'

export enum CustomFieldValueDataTypeEnum {
  CustomFieldValueNumber = 'CustomFieldValueNumber',
  CustomFieldValueBoolean = 'CustomFieldValueBoolean',
  CustomFieldValueString = 'CustomFieldValueString',
  CustomFieldValueDate = 'CustomFieldValueDate',
  CustomFieldValueDateTime = 'CustomFieldValueDateTime',
  CustomFieldValueOptions = 'CustomFieldValueOptions',
}

export type CustomFieldValueData = {
  customFieldCode: string
  dataType: CustomFieldValueDataTypeEnum
  value: number | boolean | string | string[]
}
