export type RoleList = string[]

export type EngageChannel = {
  channelId: number
  channelDisplay: string
  activate: boolean
}

export enum TransferScopeEnum {
  ALL_SKILLS = 'ALL_SKILLS',
  SKILLED_ONLY = 'SKILLED_ONLY',
  TRAINED_AND_SKILLED = 'TRAINED_AND_SKILLED',
}

export enum AssignOnTransferEnum {
  NOT_ALLOWED = 'NOT_ALLOWED',
  OPTIONAL = 'OPTIONAL',
  MANDATORY = 'MANDATORY',
}

export const enum DistributionModeEnum {
  PULL_AUTO_ASSIGN = 'PULL_AUTO_ASSIGN',
  PULL_EXPLICIT_ASSIGN = 'PULL_EXPLICIT_ASSIGN',
  PUSH = 'PUSH',
  PUSH_ON_DEMAND = 'PUSH_ON_DEMAND',
}

export interface UserSession {
  id: number
  fullName: string
  engage: boolean
  engageChannels?: Array<EngageChannel>
  engageChannelsAuthority: boolean
  authorities: AuthoritiesEnum[]
  spaces: string[]
  skills: string[]
  trainedSkills: string[]
  transferScope: TransferScopeEnum
  distributionMode: DistributionModeEnum
  pushTempo?: number
  displayAvailableTicketCount?: boolean
  timeZone: string
  locale: string
  defaultCountryPhoneCode: number
  state: string | null
  screenIdTicketList: number
  screenIdClientList: number
  screenIdInteractionList: number
  screenIdClientEdit: number
  searchConfigs: UUID[]
  assignOnTransfer: AssignOnTransferEnum
  login: string
  teamCode: string
  teams: string[]
}

export type SessionStatus = {
  keepAliveStatus: 'OK' | 'KO'
}

export const enum AuthoritiesEnum {
  TICKETS_SEARCH = 'TICKETS_SEARCH',
  CUSTOMERS_SEARCH = 'CUSTOMERS_SEARCH',
  SEARCH_INTERACTION = 'SEARCH_INTERACTION',
  TICKET_VIEW_IN_MY_SPACES = 'TICKET_VIEW_IN_MY_SPACES',
  TICKET_SEARCH_ALL_CREATORS = 'TICKET_SEARCH_ALL_CREATORS',
  TICKET_SEARCH_MYUSERGROUPS_CREATORS = 'TICKET_SEARCH_MYUSERGROUPS_CREATORS',
  ADMIN_USERS = 'ADMIN_USERS',
  CTI_ACCESS = 'CTI_ACCESS',
  SEARCH_PUBLIC_FILTER_CREATE = 'SEARCH_PUBLIC_FILTER_CREATE',
  BREAK = 'BREAK',
  ADMIN_USERPROFILES = 'ADMIN_USERPROFILES',
  ADMIN_USERGROUPS = 'ADMIN_USERGROUPS',
  ADMIN_AUTHENTICATION = 'ADMIN_AUTHENTICATION',
  ADMIN_SKILLGROUPS = 'ADMIN_SKILLGROUPS',
  ADMIN_SKILLS = 'ADMIN_SKILLS',
  ADMIN_SCREENS = 'ADMIN_SCREENS',
  ADMIN_SCHEDULE = 'ADMIN_SCHEDULE',
  ADMIN_PAUSE_TYPES = 'ADMIN_PAUSE_TYPES',
  ADMIN_ACTIVITIES = 'ADMIN_ACTIVITIES',
  ADMIN_CANNED_RESPONSES = 'ADMIN_CANNED_RESPONSES',
  ADMIN_CUSTOM_FIELDS = 'ADMIN_CUSTOM_FIELDS',
  ADMIN_ADDRESSBOOK = 'ADMIN_ADDRESSBOOK',
  ADMIN_CONTACT_LISTS = 'ADMIN_CONTACT_LISTS',
  ADMIN_CHANNELS = 'ADMIN_CHANNELS',
  ADMIN_ENGAGE_CHANNELGROUP = 'ADMIN_ENGAGE_CHANNELGROUP',
  ADMIN_CSS_SOURCE = 'ADMIN_CSS_SOURCE',
  ADMIN_CHAT = 'ADMIN_CHAT',
  ADMIN_CTI = 'ADMIN_CTI',
  ADMIN_MAIL_MAILBOX = 'ADMIN_MAIL_MAILBOX',
  ADMIN_MAIL_SMTP = 'ADMIN_MAIL_SMTP',
  ADMIN_MAIL_ENVELOPE = 'ADMIN_MAIL_ENVELOPE',
  ADMIN_MAIL_ACKNOWLEDGMENTS = 'ADMIN_MAIL_ACKNOWLEDGMENTS',
  ADMIN_RULES = 'ADMIN_RULES',
  ADMIN_SMS_PROVIDER = 'ADMIN_SMS_PROVIDER',
  ADMIN_SMS_SENDER = 'ADMIN_SMS_SENDER',
  ADMIN_SELFCARE_PORTAL = 'ADMIN_SELFCARE_PORTAL',
  ADMIN_SOCIAL_FACEBOOK = 'ADMIN_SOCIAL_FACEBOOK',
  ADMIN_SOCIAL_WHATSAPP = 'ADMIN_SOCIAL_WHATSAPP',
  ADMIN_SOCIAL_INSTAGRAM = 'ADMIN_SOCIAL_INSTAGRAM',
  ADMIN_TICKETS_SEARCH_SCREEN = 'ADMIN_TICKETS_SEARCH_SCREEN',
  ADMIN_TICKETS_LISTING_SCREEN = 'ADMIN_TICKETS_LISTING_SCREEN',
  ADMIN_TYPOLOGIES = 'ADMIN_TYPOLOGIES',
  ADMIN_REASONS = 'ADMIN_REASONS',
  ADMIN_PRIORITIES = 'ADMIN_PRIORITIES',
  ADMIN_EMERGENCIES = 'ADMIN_EMERGENCIES',
  ADMIN_PRODUCTS = 'ADMIN_PRODUCTS',
  ADMIN_POLARITY_SENTIMENT = 'ADMIN_POLARITY_SENTIMENT',
  ADMIN_SCREENS_CUSTOMER = 'ADMIN_SCREENS_CUSTOMER',
  ADMIN_CLIENTS_LISTING_SCREEN = 'ADMIN_CLIENTS_LISTING_SCREEN',
  ADMIN_INTERACTIONS_LISTING_SCREEN = 'ADMIN_INTERACTIONS_LISTING_SCREEN',
  ADMIN_EXTERNAL_INTERACTION_TYPES = 'ADMIN_EXTERNAL_INTERACTION_TYPES',
  ADMIN_SEARCH_CRITERIA = 'ADMIN_SEARCH_CRITERIA',
  ADMIN_TICKET_EXPORT_DEFINITION = 'ADMIN_TICKET_EXPORT_DEFINITION',
  ADMIN_CUSTOMER_EXPORT_DEFINITION = 'ADMIN_CUSTOMER_EXPORT_DEFINITION',
  ADMIN_INTERACTION_EXPORT_DEFINITION = 'ADMIN_INTERACTION_EXPORT_DEFINITION',
  ADMIN_FILE_DEFINITIONS = 'ADMIN_FILE_DEFINITIONS',
  ADMIN_FILE_RULES = 'ADMIN_FILE_RULES',
  ADMIN_SYSTEM_PARAM = 'ADMIN_SYSTEM_PARAM',
  ADMIN_BOT = 'ADMIN_BOT',
  ADMIN_TRIGGER = 'ADMIN_TRIGGER',
  ADMIN_NOTIFICATIONS = 'ADMIN_NOTIFICATIONS',
  ADMIN_TICKET_ARCHIVING = 'ADMIN_TICKET_ARCHIVING',
  ADMIN_SATISFACTION_SURVEY = 'ADMIN_SATISFACTION_SURVEY',
  ADMIN_SATISFACTION = 'ADMIN_SATISFACTION',
  ADMIN_QUALITY_GRID = 'ADMIN_QUALITY_GRID',
  ADMIN_SATISFACTION_SCRIPT = 'ADMIN_SATISFACTION_SCRIPT',
  ADMIN_REGIONAL_SETTINGS = 'ADMIN_REGIONAL_SETTINGS',
  ADMIN_TRANSLATION = 'ADMIN_TRANSLATION',
  ADMIN_RESOURCES = 'ADMIN_RESOURCES',
  ADMIN_WORKFLOW_TRANSITIONS = 'ADMIN_WORKFLOW_TRANSITIONS',
  ADMIN_INNSO_LAB = 'ADMIN_INNSO_LAB',
  ADMIN_LOGOS = 'ADMIN_LOGOS',
  ADMIN_INNSO_VERSIONS = 'ADMIN_INNSO_VERSIONS',
  ADMIN_KNOWLEDGE = 'ADMIN_KNOWLEDGE',
  SEARCH_MAIL = 'SEARCH_MAIL',
  ADMIN_QUIZ_SESSION = 'ADMIN_QUIZ_SESSION',
  CTI_LINK_RECORDER = 'CTI_LINK_RECORDER',
  TICKET_TRANSFER = 'TICKET_TRANSFER',
  TICKET_FORCE_ASKFORVALIDATION = 'TICKET_FORCE_ASKFORVALIDATION',
  TICKET_FORCE_STACK = 'TICKET_FORCE_STACK',
  TICKET_WAKEUP = 'TICKET_WAKEUP',
  SOCIAL_FACEBOOK_CREATE_POST = 'SOCIAL_FACEBOOK_CREATE_POST',
  TICKETS_EXTRACT = 'TICKETS_EXTRACT',
  CUSTOMERS_EXTRACT = 'CUSTOMERS_EXTRACT',
  EXPORT_INTERACTION = 'EXPORT_INTERACTION',
}
