import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { type Typology } from '@/common/types/typology'
import { fetchTypologies } from '@/common/services/TypologyService'
import { removeEntry } from '@/common/services/LocalStorageService'
import { API_CACHE_KEYS } from '@/common/types/cacheConstants'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

export const useTypologyStore = defineStore('typology', () => {
  const typologies: Ref<Typology[]> = ref([])

  async function getTypologies(invalidate: boolean = false) {
    try {
      if (invalidate) {
        removeEntry(API_CACHE_KEYS.TYPOLOGIES)
        removeEntry(PrefixTranslationsEnum.SPACE)
        removeEntry(PrefixTranslationsEnum.TYPOLOGY)
      }
      ;[typologies.value] = await fetchTypologies(API_CACHE_KEYS.TYPOLOGIES)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    typologies,
    getTypologies,
  }
})
