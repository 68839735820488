import { type Ref, ref } from 'vue'
import { defineStore } from 'pinia'
import configResolver from '@/common/helpers/configResolver'
import { getLogoInstance } from '@/common/services/AppearancesService'
import { SearchStoreIdEnum } from '@/search/types/search'

export const useApplicationStore = defineStore('application', () => {
  const ready: Ref<boolean> = ref(false)
  const legacyFrame = ref<HTMLIFrameElement>()
  const active: Ref<boolean> = ref(true)
  const disabled: Ref<boolean> = ref(false)
  const logo: Ref<string> = ref('')
  const engage: Ref<boolean> = ref(false)
  const modalOpened: Ref<boolean> = ref(false)
  const searchStoreId: Ref<SearchStoreIdEnum> = ref(SearchStoreIdEnum.MAIN)
  const lastSearchOrigin: Ref<string | null> = ref(null)

  const getLogo = async () => {
    let resLogoInstance = `${configResolver.pathname}resources/img/logo-login-innso.png`
    try {
      resLogoInstance = await getLogoInstance()
    } catch {
      console.log('Unable to retrieve instance logo')
    } finally {
      logo.value = resLogoInstance
    }
  }

  const resetContextualSearch = () => {
    lastSearchOrigin.value = null
  }

  return {
    ready,
    legacyFrame,
    active,
    disabled,
    logo,
    getLogo,
    engage,
    modalOpened,
    searchStoreId,
    lastSearchOrigin,
    resetContextualSearch,
  }
})
