import HttpService from '@/common/services/HttpService'
import { SkillsIncludeEnum, type SkillDataV1, type Skill } from '@/common/types/skills'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { get as getCachedData } from '@/common/services/LocalStorageService'

const ServicePathV1 = '/api/v1/skills'
const ServicePathV2 = '/api/v2/skills'

interface SkillByCodeServiceParams {
  skillCode: string
}

interface SkillsServiceParams {
  space?: number
  all?: boolean
  includeTrainedSkills?: boolean
}

/**
 * @deprecated
 */
export const getSkills = ({ space, all, includeTrainedSkills }: SkillsServiceParams = {}) => {
  return HttpService.getData<SkillDataV1[]>(ServicePathV1, {
    params: {
      space,
      includeAll: all,
      includeTrainedSkills,
    },
  })
}

export const getSkillByCode = ({ skillCode }: SkillByCodeServiceParams) => {
  return HttpService.getData<Skill>(`${ServicePathV2}/${skillCode}`)
}

export const getSkillsV2 = async (cacheKey: string): Promise<Skill[]> => {
  return getCachedData<Skill[]>(cacheKey, ServicePathV2, {
    params: {
      'include-deactivated': true,
      include: SkillsIncludeEnum.PRODUCTIVITY,
    },
  })
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchSkills = async (cacheKey: string): Promise<any> => {
  return await Promise.all([
    getSkillsV2(cacheKey),
    loadEnumTranslations(PrefixTranslationsEnum.SPACE),
    loadEnumTranslations(PrefixTranslationsEnum.SKILL),
  ])
}
