import HttpService from '@/common/services/HttpService'
import type { Space } from '@/common/types/spaces'
import { loadEnumTranslations } from '@/common/services/LocalizationService'
import { PrefixTranslationsEnum } from '@/common/types/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'
import i18n from '@/plugins/i18n'
const { t } = i18n.global

const serviceRoot = '/api/v1/spaces'

export const getSpaces = async (): Promise<Space[]> => {
  return HttpService.getData<Space[]>(serviceRoot)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fetchSpaces = async (): Promise<any> => {
  return await Promise.all([getSpaces(), loadEnumTranslations(PrefixTranslationsEnum.SPACE, false)])
}

export const getSpaceOptions = (spaces: Space[]): Array<{ label: string; value: string }> => {
  const sessionStore = useSessionStore()
  const options: Array<{ label: string; value: string }> = []
  for (const space of spaces) {
    if (!space.active || !sessionStore.spaces.includes(space.name)) continue

    options.push({
      label: t(space.name),
      value: space.name,
    })
  }
  return options
}
