import { ref, type Ref } from 'vue'
import { defineStore } from 'pinia'
import { type Skill } from '@/common/types/skills'
import { fetchSkills } from '@/common/services/SkillsService'
import { removeEntry } from '@/common/services/LocalStorageService'
import { API_CACHE_KEYS } from '@/common/types/cacheConstants'
import { PrefixTranslationsEnum } from '@/common/types/i18n'

export const useSkillStore = defineStore('skill', () => {
  const skills: Ref<Skill[]> = ref([])

  async function getSkills(invalidate: boolean = false) {
    try {
      if (invalidate) {
        removeEntry(API_CACHE_KEYS.SKILLS)
        removeEntry(PrefixTranslationsEnum.SPACE)
        removeEntry(PrefixTranslationsEnum.SKILL)
      }
      ;[skills.value] = await fetchSkills(API_CACHE_KEYS.SKILLS)
    } catch (error) {
      console.log(error)
    }
  }

  return {
    skills,
    getSkills,
  }
})
