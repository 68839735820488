import HttpService from '@/common/services/HttpService'
import { get as getCachedData } from '@/common/services/LocalStorageService'
import {
  type FieldData,
  type CustomFieldData,
  type FieldContextEnum,
  type BasicField,
  EntityFieldCodeEnum,
  CUSTOMFIELD_CODE_PREFIX,
  FieldTypeEnum,
} from '@/common/types/fields'
import { PrefixTranslationsEnum, SuffixTranslationsEnum } from '@/common/types/i18n'
import i18n from '@/plugins/i18n'
import { useSessionStore } from '@/common/stores/sessionStore'
import { addTranslations } from '@/common/services/LocalizationService'

const { t } = i18n.global

/**
 * List all available fields.
 * @param context The context where the field is applicable
 * @returns fields array
 */
export const listAllFields = async (context?: FieldContextEnum): Promise<FieldData[]> => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const params: any = {}
  if (context) params.context = context
  const [fields] = await Promise.all([
    HttpService.getData<FieldData[]>('/api/v1/model/fields', {
      params,
    }),
    loadFieldsTranslation(),
  ])
  return fields
}

/**
 * Retrieve a RefCustomField
 * @param code The id or code of the custom field
 * @returns CustomFieldData type
 */
export const getCustomField = async (code: string): Promise<CustomFieldData> => {
  const cacheKey = 'cf-' + code
  const [fields] = await Promise.all([getCachedData<CustomFieldData>(cacheKey, '/api/v1/model/fields/custom/' + code), loadFieldsTranslation()])
  return fields
}

export const loadFieldsTranslation = async (): Promise<void> => {
  const sessionStore = useSessionStore()
  await addTranslations(
    sessionStore.locale,
    [PrefixTranslationsEnum.ENTITY_FIELD, PrefixTranslationsEnum.CUSTOMFIELD, PrefixTranslationsEnum.CFOPTION],
    'fields-label',
  )
}

/**
 * You have to load bundle fields before to use this method
 * @param code Field code
 * @returns field name
 */
export function getName(fieldData: FieldData): string {
  return getFieldTranslation(fieldData)
}

/**
 * You have to load bundle fields before to use this method
 * @param code Field code
 * @returns field short Name
 */
export function getShortName(fieldData: FieldData): string {
  return getFieldTranslation(fieldData, SuffixTranslationsEnum.SHORT)
}

/**
 * @param code Field code
 * @param suffix translation suffix
 * @returns field translation
 */
export function getFieldTranslation(fieldData: FieldData, suffix: SuffixTranslationsEnum | '' = ''): string {
  if (fieldData.custom) {
    return t(PrefixTranslationsEnum.CUSTOMFIELD + fieldData.code.replace(CUSTOMFIELD_CODE_PREFIX, '') + suffix)
  }
  return t(PrefixTranslationsEnum.ENTITY_FIELD + fieldData.code + suffix)
}

/**
 * Get field label depends on field code (mainly used by search).
 * You can use getName method instead
 * @param fieldData Field data
 * @returns field label
 */
export const getFieldLabel = (fieldData: BasicField) => {
  return fieldData.custom ? fieldData.name : t('assist.label.' + fieldData.code)
}

export const getTrueLabel = (code: string) => {
  switch (code) {
    case EntityFieldCodeEnum.HAS_ATTACHMENTS:
    case EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS:
      return t('assist.dictionary.with')
    case EntityFieldCodeEnum.INTERACTION_SLA:
      return t('assist.dictionary.withinSLA')
    default:
      return t('assist.dictionary.yes')
  }
}

export const getFalseLabel = (code: string) => {
  switch (code) {
    case EntityFieldCodeEnum.HAS_ATTACHMENTS:
    case EntityFieldCodeEnum.INTERACTION_HAS_ATTACHMENTS:
      return t('assist.dictionary.without')
    case EntityFieldCodeEnum.INTERACTION_SLA:
      return t('assist.dictionary.outsideSLA')
    default:
      return t('assist.dictionary.no')
  }
}

export const isDate = (type: FieldTypeEnum | undefined): boolean => {
  return type ? [FieldTypeEnum.TIMESTAMP, FieldTypeEnum.LOCAL_DATE].includes(type) : false
}
